<template>
  <div>
    <b-card>
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="Update()"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-xl-12">
            <div class="border rounded p-2 mb-2">
              <b-media no-body vertical-align="center" class="d-block">
                <b-media-aside>
                  <b-img
                    :src="background_image"
                    style="width: 100%"
                    class="rounded mr-2 mb-1 mb-md-0 border"
                  />
                </b-media-aside>
                <div>
                  <b-media-body>
                    <b-form-group
                      class="mt-1"
                      label="Background Image"
                      label-for="about_image"
                    >
                      <b-form-file
                        id="about_image"
                        class="w-25"
                        @change="loadImage($event)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <!--email-->
                  </b-media-body>
                  <div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteImage"
                    >
                      Delete Image
                    </button>
                  </div>
                </div>
              </b-media>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="border rounded p-2 mb-2">
              <b-media no-body vertical-align="center" class="d-block">
                <b-media-aside>
                  <b-img
                    :src="icon"
                    height="100"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <div>
                  <b-media-body>
                    <b-form-group label="Icon" label-for="icon">
                      <b-form-file
                        id="icon"
                        @change="iconImage($event)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>
                    <!--email-->
                  </b-media-body>
                  <div>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteIcon"
                    >
                      Delete icon
                    </button>
                  </div>
                </div>
              </b-media>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="Link">
                  <Input
                    type="text"
                    v-model="form.link"
                    placeholder="Enter Link"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Gradient start color">
                  <Input
                    type="color"
                    v-model="form.start_color"
                    placeholder="Gradient start color"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Gradient end color">
                  <Input
                    type="color"
                    v-model="form.end_color"
                    placeholder="Gradient end color"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Direction">
                  <Input
                    type="text"
                    v-model="form.direction"
                    placeholder="direction"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Promotion text">
                  <Input
                    type="text"
                    v-model="form.promotion_text"
                    placeholder="promotion text"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Button name">
                  <Input
                    type="text"
                    v-model="form.button_name"
                    placeholder="Button name"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Count downtime">
                  <DatePicker
                    type="datetime"
                    v-model="form.countdown_time"
                    placeholder="Select datetime"
                    style="width: 100%"
                  ></DatePicker>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Start time">
                  <DatePicker
                    type="date"
                    v-model="form.start_time"
                    placeholder="Select start time"
                    style="width: 100%"
                  ></DatePicker>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="End time">
                  <DatePicker
                    type="date"
                    v-model="form.end_time"
                    placeholder="Select end time"
                    style="width: 100%"
                  ></DatePicker>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" :disabled="form.busy">
          Update
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BFormFile,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormDatepicker,
  BForm,
  BButton,
} from "bootstrap-vue";
import { formatDate } from "@/@core/utils/filter";
export default {
  data() {
    return {
      form: {
        link: null,
        background_image: null,
        icon: null,
        busy: false,
        start_color: null,
        end_color: null,
        direction: null,
        promotion_text: null,
        button_name: null,
        countdown_time: null,
        start_time: null,
        end_time: null,
      },
      background_image: null,
      icon: null,
    };
  },

  mounted() {
    this.getResult();
  },

  methods: {
    loadImage(e) {
      this.form.background_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.background_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    iconImage(e) {
      this.form.icon = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.icon = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    async getResult() {
      const promotionHeader = await this.callApi(
        "get",
        "/app/cms/promotion/header/banner"
      );

      if (promotionHeader) {
        this.form.start_color =
          promotionHeader.data.promotionHeaderBanner.start_color || null;
        this.form.end_color =
          promotionHeader.data.promotionHeaderBanner.end_color || null;
        this.form.direction =
          promotionHeader.data.promotionHeaderBanner.direction || null;
        this.form.promotion_text =
          promotionHeader.data.promotionHeaderBanner.promotion_text || null;
        this.form.button_name =
          promotionHeader.data.promotionHeaderBanner.button_name || null;
        this.form.link =
          promotionHeader.data.promotionHeaderBanner.link || null;
        this.form.countdown_time = promotionHeader.data.promotionHeaderBanner
          .countdown_time
          ? new Date(promotionHeader.data.promotionHeaderBanner.countdown_time)
          : null;
        this.form.start_time = promotionHeader.data.promotionHeaderBanner
          .start_time
          ? new Date(promotionHeader.data.promotionHeaderBanner.start_time)
          : null;
        this.form.end_time = promotionHeader.data.promotionHeaderBanner.end_time
          ? new Date(promotionHeader.data.promotionHeaderBanner.end_time)
          : null;
        this.background_image =
          promotionHeader.data.promotionHeaderBanner.background_image != null
            ? this.$store.state.base_url +
              promotionHeader.data.promotionHeaderBanner.background_image
            : this.getImage(1920, 70);
        this.icon =
          promotionHeader.data.promotionHeaderBanner.icon != null
            ? this.$store.state.base_url +
              promotionHeader.data.promotionHeaderBanner.icon
            : this.getImage(300, 300);
      }
    },
    formatDateTime(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
      return null;
    },
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },
    deleteImage() {
      axios
        .delete("/app/cms/promotion/header/banner/background/delete")
        .then((res) => {
          this.getResult();
          this.s(res.data.message);
          this.form.background_image = "null";
        })
        .catch((error) => {
          this.form.busy = false;
          this.errors = error.res.data.errors;
        });
    },
    deleteIcon() {
      axios
        .delete("/app/cms/promotion/header/banner/icon/delete")
        .then((res) => {
          this.getResult();
          this.s(res.data.message);
        })
        .catch((error) => {
          this.form.busy = false;
          this.errors = error.res.data.errors;
        });
    },
    Update() {
      const countdownTime = this.formatDateTime(this.form.countdown_time);
      const startTime = this.formatDate(this.form.start_time);
      const endTime = this.formatDate(this.form.end_time);
      const fd = new FormData();
      fd.append("link", this.form.link || "");
      fd.append(
        "background_image",
        this.form.background_image ? this.form.background_image : null
      );
      fd.append("icon", this.form.icon ? this.form.icon : "");
      fd.append("start_color", this.form.start_color || "");
      fd.append("end_color", this.form.end_color || "");
      fd.append("direction", this.form.direction || "");
      fd.append("promotion_text", this.form.promotion_text || "");
      fd.append("button_name", this.form.button_name || "");
      fd.append("countdown_time", countdownTime || "");
      fd.append("start_time", startTime || "");
      fd.append("end_time", endTime || "");
      axios
        .post("/app/cms/promotion/header/banner/update", fd)
        .then((res) => {
          this.getResult();
          this.s(res.data.message);
        })
        .catch((error) => {
          this.form.busy = false;
          this.errors = error.res.data.errors;
        });
    },
  },
  components: {
    BFormFile,
    BMedia,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BFormDatepicker,
    BMediaBody,
  },
};
</script>
